import React, {Fragment, ReactNode, useRef,FC} from "react";
import Link from "next/link";
import Image from "next/image";
import {useDispatch} from "react-redux";
import {setBuilderUrl, setPopup, setPopupName} from "../../../store/general";
import useIsInView from "../../../hooks/useInView";
import HoverImage from "../HoverImage";

interface IProps {
    item: {
        switchValue: boolean | null;
        linkValue?: string;
        customClassNames: string;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        },
        imageStyles: { width: "150%", marginLeft: "-25%" } | {};
        imageSwitchStyles: Record<string, string>;
        setInlineStyles: Record<string, string>
        linkTarget: "_self" | "_blank"
        defaultImage: string
        hoverImage?: string
    };
    dbName: string;
}

const ImageUpload: FC<IProps> = (
    {
        item: {
            switchValue,
            linkValue = "",
            linkTarget,
            customClassNames,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                parentStyles = {},
                styles: imageSwitchStyles,
            } = {},
            imageStyles,
            defaultImage,
            hoverImage
        },
        dbName
    }
) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const handleModalOpen = (): void => {
        if (switchValue) {
            dispatch(setPopupName("zoom_in"));
            dispatch(setPopup(true));
            dispatch(setBuilderUrl(defaultImage))
        }
    }

    const imagePropHandler = {
        "true": {},
        "false": {onClick: handleModalOpen},
    }
    const LinkLayoutToggle = ({children}: { children: ReactNode }) => {
        const jsxWithLayout = {
            "true": <Link href={linkValue as string}>
                <a target={linkTarget}>
                    {children}
                </a>
            </Link>,
            "false": <Fragment>{children}</Fragment>
        }
        return jsxWithLayout[`${!!linkValue}`]
    }
    const inView = useIsInView(ref, !!dataAos)
    return <div data-aos={dataAos} style={parentStyles} className={!inView ? "dataAosRef" : ""}>
        <LinkLayoutToggle>
            <div className={customClassNames} style={{
                ...imageSwitchStyles,
                ...imageStyles,
                maxWidth: "100%",
                position: 'relative'
            }} ref={ref}>
                <Image
                    src={defaultImage}
                    alt="builder image"
                    layout="fill"
                    loading="lazy"
                    style={{...imageSwitchStyles, ...imageStyles}}
                    {...imagePropHandler[`${!!linkValue}`]}
                />
                <HoverImage
                    src={hoverImage}
                    css={{ ...imageSwitchStyles, ...imageStyles }}
                />
            </div>
        </LinkLayoutToggle>
    </div>
};

export default ImageUpload;